<template>
  <v-card>
    <v-card-title>
      {{leagueName}}
    </v-card-title>
    <v-card-text>
      <!-- <v-btn
        @click="$router.push('/tiimi-admin/leagues')"
      ><v-icon>mdi-arrow-left</v-icon></v-btn> -->
      <div class="mt-5">
        <v-btn text small :color="$route.path.includes('games') ? 'indigo' : 'black'" @click="$router.push(`/tiimi-admin/leagues/${$route.params.league_id}/games`)">
          Games
        </v-btn>
        <!-- <v-btn @click="$router.push(`/tiimi-admin/leagues/${$route.params.id}/teams`)">
          Teams
        </v-btn> -->
        <v-btn text small :color="$route.path.includes('tags') ? 'indigo' : 'black'" @click="$router.push(`/tiimi-admin/leagues/${$route.params.league_id}/tags`)">
          Tags
        </v-btn>
        <v-btn text small :color="$route.path.includes('teams') ? 'indigo' : 'black'" @click="$router.push(`/tiimi-admin/leagues/${$route.params.league_id}/teams`)">
          Teams
        </v-btn>
        <!-- <v-btn @click="$router.push(`/tiimi-admin/leagues/${$route.params.id}/assets`)">
          Assets & Settings
        </v-btn> -->
      </div>
      <v-card class="mt-5" flat>
        <router-view></router-view>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  created() {
    this.initGames({league_id: this.$route.params.league_id, season: 'all'})
  },
  computed: {
    ...mapGetters('admin', [
      'leagues',
      'games',
      'leagueById'
    ]),
    leagueName() {
      return this.leagueById(this.$route.params.league_id)?.league_name
    }
  },
  methods: {
    ...mapActions('admin', [
      'initGames'
    ])
  },
}
</script>